import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import Accordion from "./Accordion.jsx"
import styles from "./AllFormats.module.scss"
import { Skeleton } from "@mui/material"
// import table from "./table.json"

const AllFormats = () => {
    const [expanded, setExpanded] = useState(false);
    const [formats, setFormats] = useState([]);

    const handleChange =
        (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };

    useEffect(() => {
        var url = process.env.REACT_APP_SERVER_URL + "/Formats/formats";

        fetch(url)
            .then((response) => {
                return response.json()
            }).then((data) => {
                setFormats(data);
            }).catch(function (error) {
                console.error(error)
            });
    }, [])

    return (
        <>
            {formats.length > 0 ?
                <Paper
                    className={styles.PaperCustom}
                    elevation={0}
                    variant="outlined"
                >
                    {formats && formats.map((format, i) => {
                        return <Accordion key={i} id={i} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} format={format} />
                    })} </Paper>
                :
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={window.innerWidth < 500 ? '100%' : '40%'}
                    height={window.innerWidth < 500 ? '50vh' : '90%'}
                />
            }
        </>
    );
}

export default AllFormats;