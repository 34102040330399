import React from "react";
import Navbar from "../components/Navbar/Navbar.jsx";
import CreateBudget from "../sections/CreateBudget/CreateBudget.jsx";

export default function Calculadora() {
    return (
        <div style={{ height: '100vh', backgroundColor: 'rgb(169, 196, 202)' }}>
            <Navbar page="budget" />
            <CreateBudget />
        </div>
    )
}